.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 9%;
    left: 14%;
    margin: 12px;
    border-radius: 4px;
}

.redmarker {
    color: #FF0000;
}

.marker {
    min-width: 50px;
    height: 25px;
    background: #349C55;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    color: white;  
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .markerOrange {
    min-width: 50px;
    height: 25px;
    background: #FF7E00;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    color: white;  
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #4f4f4f;
    margin: auto;
    margin-top:2px;
  }

.mapboxgl-popup {
    max-width: 400px;
}

#MapMenu {
    position: absolute;
    background: #efefef;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    width: 20%;
    height: auto;
    z-index: 9999;
}